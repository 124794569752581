<template lang="html">
  <div>
    <SearchBar v-model="search" debounced />
    <div v-if="hasRecommendations" :class="$style.switchContainer">
      <SimpleSwitch v-model="showRecommendations">
        <IxRes>core.products.filters.showRecommendations</IxRes>
      </SimpleSwitch>
    </div>
    <div :class="$style.switchContainer">
      <SimpleSwitch v-model="onlyPreviouslyUsed">
        <IxRes>core.products.filters.onlyPreviouslyUsed</IxRes>
      </SimpleSwitch>
    </div>

    <hr />

    <slot />
  </div>
</template>

<script>
import SearchBar from '@components/search/SearchBar'
import SimpleSwitch from '@components/forms/SimpleSwitch'

export default {
  components: {
    SearchBar,
    SimpleSwitch
  },
  props: {
    value: {
      type: Object,
      required: true
    },
    hasRecommendations: Boolean
  },
  computed: {
    search: {
      get () {
        return this.value.search
      },
      set (search) {
        this.$emit('input', {...this.value, search})
      }
    },
    showRecommendations: {
      get () {
        return this.value.showRecommendations
      },
      set (showRecommendations) {
        this.$emit('input', {...this.value, showRecommendations})
      }
    },
    onlyPreviouslyUsed: {
      get () {
        return this.value.onlyPreviouslyUsed
      },
      set (onlyPreviouslyUsed) {
        this.$emit('input', {...this.value, onlyPreviouslyUsed})
      }
    }
  }
}
</script>

<style lang="scss" module>
.switchContainer {
  margin-top: 0.5em;
}
</style>
