<template lang="html">
  <TileBase :color="product.color">
    <template #icon-left>
      <div v-if="isRecommended" :class="$style.recommended" :title="$i18n.translate('core.products.isRecommended')">
        <FontAwesomeIcon
          :icon="icons.recommended"
          fixed-width
        />
      </div>
      <div v-else-if="product.orgUnitId" :title="entityLookup[product.orgUnitId].name">
        <FontAwesomeIcon
          :icon="icons.custom"
          fixed-width
        />
      </div>
    </template>
    <template #icon-right>
      <div v-if="wasPreviouslyUsed" :class="$style.recommended" :title="$i18n.translate('core.products.wasPreviouslyUsed')">
        <FontAwesomeIcon
          :icon="icons.history"
          fixed-width
        />
      </div>
    </template>
    <template #name>
      <span :title="product.name">
        {{ product.name }}
      </span>
    </template>
    <template #abbreviation>
      <span :title="product.name">
        {{ product.name.substring(0, 3) }}
      </span>
    </template>

    <slot />
  </TileBase>
</template>

<script>
import TileBase from '@components/TileBase'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import {faHome, faAward, faHistory} from '@fortawesome/free-solid-svg-icons'

export default {
  components: {
    TileBase,
    FontAwesomeIcon
  },
  props: {
    /**
     * base {orgUnitId?: string, name: string, color: string, isArchived: boolean}
     */
    product: {
      type: Object,
      required: true
    },
    entityLookup: {
      type: Object,
      required: true
    },
    isRecommended: Boolean,
    wasPreviouslyUsed: Boolean
  },
  computed: {
    icons () {
      return {
        custom: faHome,
        recommended: faAward,
        history: faHistory
      }
    }
  }
}
</script>

<style lang="scss" module>
.recommended {
  color: #8822ff;
}
</style>
