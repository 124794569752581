<template>
  <div class="simple-application-maps">
    <FrsRouterBackLink target="fieldDashboard">
      <template #text>
        <IxRes>frs.simpleApplicationMaps.buttons.backToDashboard</IxRes>
      </template>
    </FrsRouterBackLink>
    <h3>
      <IxRes>Areas.SimpleApplicationMaps.SR_SimpleApplicationMaps.CommonSettings_header</IxRes>
    </h3>

    <TextInput
      v-model="name"
      name="AppMapNameInput"
      placeholder="Optional"
    >
      <template #label>
        <IxRes>frs.name</IxRes>
      </template>
    </TextInput>

    <LabelAndMessage>
      <template #label>
        <IxRes>Areas.SimpleApplicationMaps.SR_SimpleApplicationMaps.CulticationSelection_header</IxRes> *
      </template>
      <FrsCultivationPicker
        v-model="computedCultivationId" name="cultivationId"
        :field-id="fieldId" required
      />
    </LabelAndMessage>

    <MapPaletteSelector
      v-if="zoneMap && zoneMap.zones" v-model="zoneMapPalette"
      :options="paletteOptions" :map="zoneMap"
    />

    <FrsZoneMapSelection
      v-model="zoneMapId" name="zoneMapSelection"
      :field-id="fieldId"
      hide-label
      show-on-map
      required
    >
      <template #label>
        <IxRes :context="{0: fieldName}">Areas.FieldRecordSystem.SR_FieldRecordSystem.ZoneMapFor</IxRes>
      </template>
    </FrsZoneMapSelection>

    <div class="zone-form">
      <section class="settings">
        <FrsProductPicker
          v-model="productId" name="productPicker"
          label="Product"
          :available-product-filter="availableProductFilter"
          :readonly="!cultivationId"
          required
        >
          <template #label>
            <IxRes>Areas.SimpleApplicationMaps.SR_SimpleApplicationMaps.ProductSettings_header</IxRes>
          </template>
        </FrsProductPicker>

        <template v-if="zoneMap && productId">
          <h3>
            <IxRes>Areas.SimpleApplicationMaps.SR_SimpleApplicationMaps.ZoneMapSettings_header</IxRes>
          </h3>

          <HelpBox v-if="!zoneMap">
            <IxRes>Areas.SimpleApplicationMaps.SR_SimpleApplicationMaps.NoZoneMapSelected_hint</IxRes>
          </HelpBox>
          <HelpBox v-else-if="!zoneCount" type="warning">
            <IxRes>Areas.SimpleApplicationMaps.SR_SimpleApplicationMaps.DefectiveZonemapSelected_hint</IxRes>
          </HelpBox>

          <div class="zonemap-settings">
            <div class="heterogenity-settings">
              <FrsHeterogenityDisplay :zone-map="zoneMap" />

              <template v-if="zoneMap.heterogenity && zoneMap.heterogenity !== 'None'">
                <label><IxRes>Areas.SimpleApplicationMaps.SR_SimpleApplicationMaps.Mode_header</IxRes></label>

                <SimpleSwitch v-model="byHeterogenity">
                  <!-- <IxRes slot="offLabel" :resource="SR_SimpleApplicationMaps">ModeManual_label</IxRes> -->
                  <IxRes>Areas.SimpleApplicationMaps.SR_SimpleApplicationMaps.ModeByHeterogenity_label</IxRes>
                </SimpleSwitch>

                <template v-if="byHeterogenity">
                  <QuantityInput
                    v-model="averageAmountWithUnit" name="averageAmountInput"
                    :unit-options="validUnits"
                    required
                  >
                    <template #label>
                      <IxRes>Areas.SimpleApplicationMaps.SR_SimpleApplicationMaps.AverageApplicationAmount_label</IxRes>
                    </template>
                  </QuantityInput>
                </template>
              </template>
              <template v-if="!zoneMap.heterogenity || !byHeterogenity">
                <VueSelectize
                  v-model="unit"
                  :options="validUnits"
                  name="units"
                >
                  <template #label>
                    <IxRes>Areas.SimpleApplicationMaps.SR_SimpleApplicationMaps.unit_label</IxRes>
                  </template>
                </VueSelectize>
              </template>
              <div class="reverse-zone-amounts">
                <SimpleSwitch v-model="reverseZoneAmounts">
                  <!-- <IxRes slot="offLabel" :resource="SR_SimpleApplicationMaps">ModeManual_label</IxRes> -->
                  <IxRes>Areas.SimpleApplicationMaps.SR_SimpleApplicationMaps.ReverseZoneAmounts</IxRes>
                </SimpleSwitch>
              </div>
            </div>
            <div v-if="zoneMap.zones" class="zone-values">
              <FrsZoneValueInput
                v-model="zoneAmounts"
                name="zoneValueInput"
                :zone-map="zoneMap"
                :unit="unit"
              />
            </div>
          </div>
        </template>
      </section>
    </div>
    <hr>
    <div v-if="zoneMap && zoneMap.zones && productId" class="amounts">
      <FrsZonesAverage
        :zone-values="zoneAmounts"
        :zone-map="zoneMap"
        :unit="unit"
      />

      <div class="total-sum">
        <FrsZonesTotalSum
          :zone-values="zoneAmounts"
          :zone-map="zoneMap"
          :unit="unit"
        />
      </div>
    </div>

    <div class="buttons">
      <IxButton
        save large
        @click="onCreateApplicationMap"
      />
    </div>
    <FrsLoadingIndicator :requests="['simpleApplicationMap.cultivation.loadDetails']" :loading="saving" saving />
  </div>
</template>

<script>
import {sortBy} from 'lodash'
import {mapState, mapActions, mapMutations, mapGetters} from 'vuex'

import {mapFormFields, mapResources} from '@helpers/vuex'
import {isMaize} from '@helpers/products'
import {spreadByHeterogenity} from '@helpers/map-creation'
import {notifications} from 'src/js/infrastructure'

import FormPartMixin from '@components/forms/FormPartMixin'
import PermissionMixin from '@mixins/PermissionMixin'

import FrsLoadingIndicator from '@frs/components/base/FrsLoadingIndicator'
import IxButton from '@components/IxButton'
import FrsZoneMapSelection from '@frs/components/base/FrsZoneMapSelection'
import FrsCultivationPicker from '@frs/components/base/FrsCultivationPicker'
import QuantityInput from '@components/forms/QuantityInput'
import SimpleSwitch from '@components/forms/SimpleSwitch'
import HelpBox from '@components/help/HelpBox'
import LabelAndMessage from '@components/forms/input-base/LabelAndMessage'
import FrsZoneValueInput from '@frs/components/base/FrsZoneValueInput'
import FrsZonesTotalSum from '@frs/components/base/FrsZonesTotalSum'
import FrsZonesAverage from '@frs/components/base/FrsZonesAverage'
import FrsHeterogenityDisplay from '@frs/components/base/FrsHeterogenityDisplay'
import TextInput from '@components/forms/TextInput'
import FrsProductPicker from '@frs/components/base/FrsProductPicker'
import FrsRouterBackLink from '@frs/components/base/FrsRouterBackLink'
import MapPaletteSelector from '@components/MapPaletteSelector'
import VueSelectize from '@components/VueSelectize'

export default {
  components: {
    FrsZoneMapSelection,
    FrsZonesTotalSum,
    FrsZonesAverage,
    FrsRouterBackLink,
    HelpBox,
    IxButton,
    FrsCultivationPicker,
    TextInput,
    LabelAndMessage,
    QuantityInput,
    SimpleSwitch,
    FrsZoneValueInput,
    FrsHeterogenityDisplay,
    FrsProductPicker,
    MapPaletteSelector,
    VueSelectize,
    FrsLoadingIndicator
  },
  mixins: [
    FormPartMixin,
    PermissionMixin
  ],
  data () {
    return {
      saving: false
    }
  },
  computed: {
    ...mapResources([
      '@sam.SR_SimpleApplicationMaps'
    ]),
    ...mapFormFields('fieldRecordSystem/mapManagement', [
      'ui.zoneMapPalette'
    ]),
    ...mapFormFields('fieldRecordSystem/simpleApplicationMaps', [
      'parameters.name',
      'parameters.zoneMapId',
      'parameters.cultivationId',
      'parameters.productId',
      'parameters.unit',
      'parameters.zoneAmounts',
      'byHeterogenity',
      'averageAmount',
      'reverseZoneAmounts'
    ]),
    ...mapGetters('fieldRecordSystem', [
      'fieldId',
      'fieldName'
    ]),
    ...mapGetters('fieldRecordSystem/simpleApplicationMaps', [
      'zoneMap',
      'validUnits'
    ]),
    ...mapState('masterData', {
      crops: state => state.cropUsages
    }),
    ...mapGetters('masterData', [
      'products'
    ]),
    computedCultivationId: {
      get () { return this.cultivationId },
      set (id) { this.addUnitsAndGetCropusageByCultivationId(id) }
    },
    zoneCount () {
      const zoneNumbers = this.zoneMap.zones !== undefined ? this.zoneMap.zones.map((zone, i) => zone.number || (zone.name ? Number(zone.name) : (i + 1))) : []
      return (this.zoneMap.metaData && this.zoneMap.metaData.countOfClasses) || Math.max(...zoneNumbers)
    },
    averageAmountWithUnit: {
      get () {
        return {amount: this.averageAmount, unit: this.unit}
      },
      set ({amount, unit}) {
        this.averageAmount = amount
        this.unit = unit
      }
    },
    paletteOptions () {
      if (!this.zoneMap) return []

      if (this.zoneMap.category.startsWith('BasicFertilization')) {
        return ['relative', 'agrosat', 'lufa']
      } else {
        return ['relative', 'agrosat', 'absolute']
      }
    },
    zoneMapIds () {
      return [this.zoneMapId]
    },
    fieldIdByZoneMapId () {
      return {[this.zoneMapId]: this.fieldId}
    },
    availableProductFilter () {
      if (this.$can('feature/hide-corn-from-simple-application-maps')) {
        return product => !isMaize(product)
      }

      return undefined
    }
  },
  methods: {
    ...mapActions('fieldRecordSystem/simpleApplicationMaps', [
      'createApplicationMap',
      'viewResultPage',
      'addUnitsAndGetCropusageByCultivationId'
    ]),
    ...mapActions('fieldRecordSystem/applicationMaps', [
      'createApplicationMapSuccess'
    ]),
    ...mapMutations('fieldRecordSystem/simpleApplicationMaps', [
      'reset',
      'setZoneMapId',
      'setCultivationId'
    ]),
    async onCreateApplicationMap () {
      this.validate()

      await this.$nextTick()

      if (this.state !== 'success') return
      this.saving = true
      try {
        const applicationMapId = (await this.createApplicationMap()).data

        notifications.success(this.SR_SimpleApplicationMaps.CreationSuccess)
        await this.createApplicationMapSuccess(applicationMapId)
      } catch (error) {
        let notification = this.SR_SimpleApplicationMaps.CreationError
        if (error.response.data.failure) {
          notification += '\n' + this.$i18n.translate(`frs.exceptions.${error.response.data.failure}`)
        }
        notifications.error(notification)
      } finally {
        this.saving = false
        await this.viewResultPage()
      }
    }
  },
  watch: {
    fieldId: {
      immediate: true,
      handler (fieldId) {
        if (this.$route.params && !this.$route.params.mapId) {
          this.setZoneMapId(null)
        }
        this.setCultivationId(null)
      }
    },
    zoneMap: {
      deep: true,
      handler (zoneMap) {
        if (zoneMap && zoneMap.zones && this.averageAmount) {
          this.zoneAmounts = spreadByHeterogenity(this.averageAmount, zoneMap)
        } else {
          this.zoneAmounts = null
        }
      }
    },
    averageAmount (averageAmount) {
      if (averageAmount === null) return

      if (this.zoneMap && this.zoneMap.zones) {
        this.zoneAmounts = spreadByHeterogenity(averageAmount, this.zoneMap)
      } else {
        this.zoneAmounts = null
      }
    },
    reverseZoneAmounts () {
      if (this.zoneAmounts === null) return
      const previousAmounts = {...this.zoneAmounts}

      const sortedZones = sortBy(this.zoneMap.zones, 'number')

      sortedZones.forEach((zone, index) => {
        const mirroredId = sortedZones[sortedZones.length - index - 1].id

        this.zoneAmounts[zone.id] = previousAmounts[mirroredId]
      })
    },
    productId (newValue, oldValue) {
      const hasProductTypeChanged = oldValue && this.products[oldValue].type !== this.products[newValue].type

      if (!oldValue || hasProductTypeChanged) {
        this.unit = this.products[newValue].type === 'protection' ? 'l/ha' : 'kg/ha'
      }
    }
  },
  created () {
    this.unit = this.validUnits[0]
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      const mapId = to.params && to.params.mapId ? to.params.mapId : null
      vm.reset()
      if (mapId) {
        vm.setZoneMapId(mapId)
      }
    })
  }
}
</script>

<style lang="scss" scoped>
.zone-form {
  display: flex;

  .settings {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: stretch;

    .product-selection {
      display: flex;
    }

    .zonemap-settings {
      display: flex;
      justify-content: space-between;

      .heterogenity-settings {
        width: 50%;
      }

      .zone-values {
        width: 40%;
      }
    }
  }
}
.reverse-zone-amounts {
  margin-top: 5px;
}
.buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.amounts {
  display: flex;
  justify-content: space-between;

  .total-sum {
    width: 40%;
  }
}
</style>
