<template>
  <div style="position: relative;">
    <VueSelectize
      v-model="selectedZoneMapId" :required="required"
      :label="label"
      :options="zoneMapIds" :fields="fieldConfig"
      allow-empty placeholder="Keine Zonenkarte"
      :disabled="disabled || !fieldId"
      name="ZonemapSelectionSelectize"
    >
      <!-- TODO move optional label into slot as default value, remove hideLabel flag -->
      <template v-if="!hideLabel" slot="label">
        {{ optionalLabel }}
      </template>
      <slot slot="label" name="label" />
    </VueSelectize>
    <FrsLoadingIndicator small :loading="loading" :error="error" />

    <FrsZoneMapLayer v-if="showOnMap && value" :zone-map-id="value" />
  </div>
</template>

<script>
import {mapState, mapGetters, mapActions} from 'vuex'

import VueSelectize from '@components/VueSelectize'
import FrsZoneMapLayer from '@frs/components/base/FrsZoneMapLayer'
import FrsLoadingIndicator from '@frs/components/base/FrsLoadingIndicator'

export default {
  components: {
    VueSelectize,
    FrsZoneMapLayer,
    FrsLoadingIndicator
  },
  props: {
    value: String, // zoneMapId
    fieldId: String,
    required: {
      type: Boolean,
      default: true // NOTE for compatibility with old behaviour
    },
    disabled: Boolean,
    label: String,
    hideLabel: Boolean,
    filter: Function,
    showOnMap: Boolean,
    showInactiveMaps: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      loading: false,
      error: false
    }
  },
  computed: {
    ...mapGetters('fieldRecordSystem', [
      'entityNameLookup'
    ]),
    ...mapState('fieldRecordSystem/mapManagement', {
      zoneMapLookup: state => state.data.zoneMap.mapLookup,
      zoneMapIdsByFieldId: state => state.data.zoneMap.mapIdsByFieldId
    }),
    zoneMapIds () {
      if (!this.zoneMapIdsByFieldId[this.fieldId]) {
        return []
      }

      const ids = this.showInactiveMaps ? this.zoneMapIdsByFieldId[this.fieldId] : this.zoneMapIdsByFieldId[this.fieldId].filter(id => this.zoneMapLookup[id].active)

      return this.filter
        ? ids.filter(id => this.filter(this.zoneMapLookup[id]))
        : ids
    },
    optionalLabel () {
      if (this.label) {
        return this.label
      } else {
        return this.fieldName
      }
    },
    selectedZoneMapId: {
      get () {
        return this.zoneMapIds.includes(this.value) ? this.value : null
      },
      set (zoneMapId) {
        this.$emit('input', zoneMapId)

        if (!zoneMapId) return

        this.loading = true

        this.loadZoneMapDetails(zoneMapId)
          .then(() => {
            this.loading = false
            this.$emit('detailsLoaded')
          })
          .catch(error => {
            this.loading = false
            this.error = true
            throw error
          })
      }
    },
    fieldConfig () {
      return {
        text: id => this.zoneMapLookup[id].name,
        label: id => this.getMapLabel(this.zoneMapLookup[id])
      }
    },
    fieldName () {
      return this.entityNameLookup[this.fieldId]
    }
  },
  methods: {
    ...mapActions('fieldRecordSystem/mapManagement', [
      'loadAvailableZoneMaps',
      'loadZoneMapDetails'
    ]),
    getMapLabel (map) {
      if (map.category === 'BiomassMap') {
        return 'BMK'
      }

      switch (map.origin) {
      case 'FieldInfo': return 'MZK+'
      case 'AutomaticZoneMaps': return 'AUTO'
      case 'Import': return 'MZK+' // an imported map in this case was imported from mzk+
      default: return ''
      }
    }
  },
  watch: {
    fieldId: {
      immediate: true,
      handler (fieldId) {
        if (!fieldId) return

        this.loading = true

        this.loadAvailableZoneMaps(fieldId)
          .then(() => {
            this.loading = false
          })
          .catch(error => {
            this.loading = false
            this.error = true
            throw error
          })
      }
    },
    zoneMapIds: {
      immediate: true,
      handler (ids) {
        if (this.value && !ids.includes(this.value)) {
          this.$emit('input', null)
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
