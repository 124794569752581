<script>
import moment from 'moment'

import {mapGetters, mapActions} from 'vuex'

import {toCultivationName} from '@frs/components/cultivation-planning/helpers'
import VueSelectize from '@components/VueSelectize'

import makeReloadDataMixin from '@frs/mixins/make-reload-data-mixin'

export default {
  components: {
    VueSelectize
  },
  mixins: [makeReloadDataMixin([
    state => state.fieldRecordSystem.navigation.location.fieldId,
    state => state.fieldRecordSystem.userSettings.harvestYear
  ])
  ],
  props: {
    fieldId: String,
    required: Boolean,
    name: String,
    value: String,
    disabled: Boolean
  },
  computed: {
    ...mapGetters('fieldRecordSystem/cultivationPlanning', [
      'cultivationsByFieldId'
    ]),
    cultivations () {
      return this.cultivationsByFieldId[this.fieldId] || []
    },
    cultivationIds () {
      return this.cultivations.map(x => x.id)
    },
    fields () {
      const cultivations = this.cultivations
      return {
        text: id => toCultivationName(this.cultivations.find(x => x.id === id)),
        label: id => {
          const cultivation = cultivations.find(x => x.id === id)
          if (cultivation) {
            const {start, end} = cultivations.find(x => x.id === id).dateTimeRange
            return `${moment(start).format('LL')} - ${moment(end).format('LL')}`
          }
          return ''
        }
      }
    }
  },
  methods: {
    ...mapActions('fieldRecordSystem/cultivationPlanning', [
      'reloadCultivationsForOrgUnit'
    ])
  },
  watch: {
    cultivationIds: {
      immediate: true,
      handler (ids) {
        if (this.value && !ids.includes(this.value)) {
          this.$emit('input', null)
        }
      }
    }
  },
  render (h) {
    return h(VueSelectize, {
      props: {
        ...this.$props,
        value: this.cultivationIds.includes(this.value) ? this.value : null,
        options: this.cultivationIds,
        fields: this.fields,
        allowEmpty: true
      },
      on: {
        input: value => {
          this.$emit('input', value)
        }
      },
      scopedSlots: this.$scopedSlots
    })
  },
  reloadData () {
    return this.reloadCultivationsForOrgUnit()
  }
}
</script>

<style lang="scss" scoped>

</style>
