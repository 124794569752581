<template lang="html">
  <div :class="$style.container">
    <h3>
      <IxRes>core.products.pleaseSelectProductType</IxRes>
    </h3>

    <div :class="$style.buttons">
      <IxButton
        v-for="type in ['seed', 'protection', 'fertilizer']" :key="type"
        large
        @click="$emit('input', type)"
      >
        <IxRes>core.products.types.{{ type }}</IxRes>
      </IxButton>
    </div>
  </div>
</template>

<script>
import IxButton from '@components/IxButton'

export default {
  components: {
    IxButton
  }
}
</script>

<style lang="scss" module>
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.buttons {
  display: flex;

  > * {
    margin: 0.5em;
  }
}
</style>
