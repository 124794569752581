<template lang="html">
  <div>
    <HelpBox v-if="!zoneMap || !zoneMap.zones" type="danger">
      Invalid zonemap
    </HelpBox>
    <NumericInput
      v-model="average" readonly
      :addon="absoluteUnit"
      name="total-amount"
    >
      <template slot="label">
        <IxRes>Common.SR_Common.ZonesAverage</IxRes>
        <small class="text-muted">({{ $i18n.format(fieldSize / 10000, 'f2') }} ha)</small>
      </template>
    </NumericInput>
  </div>
</template>

<script>

import NumericInput from '@components/forms/NumericInput'
import HelpBox from '@components/help/HelpBox'

export default {
  components: {
    NumericInput,
    HelpBox
  },
  props: {
    zoneValues: Object,
    zoneMap: Object,
    unit: {
      type: String,
      required: true,
      validator: unit => /\/(?:m²|ha)/.test(unit)
    }
  },
  computed: {
    fieldSize () {
      return this.zoneMap.zones.reduce((a, b) => a + b.area, 0)
    },
    average () {
      let sum = 0

      if (this.zoneValues) {
        this.zoneMap.zones.forEach(zone => {
          if (this.convertToHectare) {
            sum += zone.area * this.zoneValues[zone.id]
          } else {
            sum += zone.area / 10000 * this.zoneValues[zone.id]
          }
        })
      }

      const area = this.convertToHectare ? this.fieldSize : this.fieldSize / 10000
      return sum / area
    },
    absoluteUnit () {
      return this.unit.replace(/\/.+$/, '')
    },
    convertToHectare () {
      return this.unit.endsWith('m²')
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
