<template lang="html">
  <div class="product-container">
    <div class="filter-sidebar">
      <slot name="sidebar" v-bind="{hasRecommendations}" />
    </div>

    <div class="main-content">
      <div class="selector scroller-container-single">
        <RecycleScroller
          ref="scrollContainer"
          class="scroller" :items="combinedItems.length ? combinedItems : placeholderItems"
          :item-size="90"
          key-field="id"
        >
          <template #default="{item: {id, triplet, isRecommended, wasPreviouslyUsed}}">
            <div v-if="id === 'placeholder'" class="grid-row">
              <div class="placeholder-content">
                <h3>
                  <IxRes>core.products.noProductsFound</IxRes>
                </h3>
              </div>
            </div>
            <div v-else-if="id === 'recommendationSeparator'" class="grid-row">
              <div class="separator-content">
                <h3>
                  <IxRes>core.products.recommendedProducts</IxRes>
                </h3>
                <h3>
                  <IxRes>core.products.otherProducts</IxRes>
                </h3>
              </div>
            </div>
            <div v-else class="grid-row">
              <div
                v-for="(product, i) in triplet"
                :key="product.id"
                class="grid-cell"
                @click="$emit('input', product.id)"
              >
                <slot
                  name="tile"
                  v-bind="{product, entityLookup, isRecommended, wasPreviouslyUsed: wasPreviouslyUsed[i]}"
                />
              </div>
            </div>
          </template>
        </RecycleScroller>
      </div>
    </div>
  </div>
</template>

<script>
import Fuse from 'fuse.js'

import {sortBy, chunk, pick} from 'lodash'

export default {
  props: {
    value: String, // productId
    type: {
      type: String,
      required: true
    },
    filter: Function,
    filters: Object,
    showArchived: Boolean,
    entityLookup: Object,
    products: Object, // {[productId]: product, ...more}
    recommendedIds: Array, // [productId, ...more]
    previouslyUsedProducts: Array
  },
  data () {
    return {
      searchString: '',
      searchKeys: ['name', 'type'],
      searchResult: null
    }
  },
  computed: {
    productArray () {
      return Object.values(this.products).map(product => ({
        ...product,
        wasPreviouslyUsed: this.previouslyUsedIds.has(product.id)
      }))
    },
    filteredByType () {
      return this.productArray.filter(product => product.type === this.type)
    },
    filteredByArchivalState () {
      return this.showArchived ? this.filteredByType : this.filteredByType.filter(x => !x.isArchived)
    },
    filteredByCustomFilter () {
      return this.filter ? this.filteredByArchivalState.filter(this.filter) : this.filteredByArchivalState
    },
    filteredBySearch () {
      if (!this.filters.search) return this.filteredByCustomFilter
      const fuse = new Fuse(this.filteredByCustomFilter, {
        keys: ['name'], threshold: 0.3
      })
      return fuse.search(this.filters.search)
    },
    standardFilterFunctions () {
      return [
        x => !this.filters.onlyPreviouslyUsed || this.previouslyUsedIds.has(x.id),
        x => !this.filters.cropId || x.cropId === this.filters.cropId,
        x => !this.filters.nutrients || Object.values(pick(x, this.filters.nutrients)).every(n => n),
        x => !this.filters.categories || this.filters.categories.every(category => x.category.includes(category))
      ]
    },
    filteredByUserFilters () {
      return this.filteredBySearch.filter(product => {
        return this.standardFilterFunctions.every(f => f(product))
      })
    },
    filteredAndSorted () {
      return sortBy(this.filteredByUserFilters, ['name'])
    },
    visibleProductTriplets () {
      return chunk(this.searchResult || this.filteredAndSorted, 3).map(triplet => {
        return {
          triplet,
          id: triplet.reduce((pre, cur) => pre + cur.id, ''),
          wasPreviouslyUsed: triplet.map(x => this.previouslyUsedIds.has(x.id))
        }
      })
    },
    recommendedSet () {
      return new Set(this.recommendedIds)
    },
    previouslyUsedIds () {
      return new Set(this.previouslyUsedProducts.map(x => x.id))
    },
    hasRecommendations () {
      // this purposely uses the list *before* user-filters are applied, to ensure the toggle does not disappear when filtering
      return this.filteredByCustomFilter.filter(product => this.recommendedSet.has(product.id)).length > 0
    },
    filteredRecommendedProducts () {
      return this.filteredAndSorted.filter(product => this.recommendedSet.has(product.id))
    },
    visibleRecommendedProductTriplets () {
      return chunk(this.filteredRecommendedProducts, 3).map(triplet => {
        return {
          triplet,
          id: triplet.reduce((pre, cur) => pre + cur.id, 'recommended'),
          isRecommended: true,
          wasPreviouslyUsed: triplet.map(x => this.previouslyUsedIds.has(x.id))
        }
      })
    },
    combinedItems () {
      return this.filters.showRecommendations && this.visibleRecommendedProductTriplets.length
        ? [
          ...this.visibleRecommendedProductTriplets,
          {id: 'recommendationSeparator'},
          ...this.visibleProductTriplets
        ]
        : this.visibleProductTriplets
    },
    placeholderItems () {
      return [
        {id: 'placeholder'}
      ]
    }
  },
  watch: {
    searchResult () {
      // TODO fix, doesn't work anymore with virtual scrolling
      this.$refs.scrollContainer.scrollTop = 0
    }
  }
}
</script>

<style lang="scss" scoped>
.product-container {
  display: flex;
  height: 100%;
}

.filter-sidebar {
  flex: 2;
  display: flex;
  flex-direction: column;
}

.main-content {
  flex: 5;
  display: flex;
  flex-direction: column;
}

.list-complete-item {
  transition: all 0.5s;
}
.list-complete-enter, .list-complete-leave-to {
  opacity: 0;
  transform: translateY(100px);
}
.list-complete-leave-active {
  position: absolute;
}

.selector {
  display: flex;
  flex-direction: column;

  max-height: 70vh;
  // border: 1px solid red;

  .grid {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: auto;
    margin-top: 10px;

    .section {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      flex-shrink: 0;
    }
  }
}

h3 {
  margin: 0;
  padding-left: 24px;
}

.filter-selection {
  margin: 0 0 6px 0;

  > * {
    margin: 0 6px 0 0;

    &:hover {
      cursor: pointer;
    }
  }
}

.scroller-container-single {
  flex: 600px;

  .scroller {
    height: 590px;
  }
}

@media (max-height: 800px) {
  h3 {
    font-size: 1.4em;
    font-weight: bold;
  }

  .scroller-container-single {
    flex: 500px;

    .scroller {
      height: 490px;
    }
  }
}

@media (max-height: 600px) {
  h3 {
    font-size: 1.2em;
    font-weight: bold;
  }

  .scroller-container-single {
    flex: 370px;

    .scroller {
      height: 360px;
    }
  }
}
.grid-row {
  height: 90px;
  display: flex;
  justify-content: center;
  padding-left: 19px; // identical to h3 padding (24px) minus tile margin (5px)
}

.separator-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.placeholder-content {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
