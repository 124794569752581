<template lang="html">
  <ProductFiltersBase v-model="baseModel" v-bind="{hasRecommendations}">
    <VueSelectize
      v-model="cropId" :options="cropIds"
      :fields="fields"
      allow-empty />
  </ProductFiltersBase>
</template>

<script>
import baseModel from './product-filters-base-model'

import ProductFiltersBase from './ProductFiltersBase'

import VueSelectize from '@components/VueSelectize'

export default {
  components: {
    ProductFiltersBase,
    VueSelectize
  },
  props: {
    value: {
      type: Object,
      required: true
    },
    cropIds: {
      type: Array,
      required: true
    },
    hasRecommendations: Boolean
  },
  computed: {
    baseModel,
    cropId: {
      get () {
        return this.value.cropId
      },
      set (cropId) {
        this.$emit('input', {...this.value, cropId})
      }
    },
    fields () {
      return {
        text: id => this.$i18n.translate(`masterData.crops.crop.${id}_name`)
      }
    }
  }
}
</script>

<style lang="scss" module>
</style>
