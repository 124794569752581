<template lang="html">
  <ProductFiltersBase v-model="baseModel" v-bind="{hasRecommendations}">
    <div class="btn-group">
      <ToggleButton v-model="nutrients" value="nitrogen">
        <IxRes>core.products.filters.n</IxRes>
      </ToggleButton>
      <ToggleButton v-model="nutrients" value="phosphorus">
        <IxRes>core.products.filters.p</IxRes>
      </ToggleButton>
      <ToggleButton v-model="nutrients" value="potassium">
        <IxRes>core.products.filters.k</IxRes>
      </ToggleButton>
    </div>
  </ProductFiltersBase>
</template>

<script>
import ToggleButton from '@components/ToggleButton'

import baseModel from './product-filters-base-model'

import ProductFiltersBase from './ProductFiltersBase'

export default {
  components: {
    ToggleButton,
    ProductFiltersBase
  },
  props: {
    value: Object,
    hasRecommendations: Boolean
  },
  computed: {
    baseModel,
    nutrients: {
      get () {
        return this.value.nutrients
      },
      set (nutrients) {
        this.$emit('input', {...this.value, nutrients})
      }
    }
  }
}
</script>

<style lang="scss" module>
</style>
