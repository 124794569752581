<template lang="html">
  <ProductPicker
    v-model="modelProxy"
    v-bind="{type, entityLookup, products, recommendedIds, previouslyUsedProducts, label, name, required, availableProductFilter, readonly, showArchived}"
    v-on="$listeners"
  />
</template>

<script>
import {mapState, mapGetters} from 'vuex'
import axios from 'axios'
import {modelProxy} from '@helpers/vuex'

import ProductPicker from '@components/product-picker/ProductPicker'

export default {
  components: {
    ProductPicker
  },
  props: {
    value: String, // productId
    label: String,
    name: String,
    required: Boolean,
    availableProductFilter: Function,
    readonly: Boolean,
    type: String,
    showArchived: Boolean
  },
  data () {
    return {
      previouslyUsedProducts: []
    }
  },
  computed: {
    modelProxy,
    ...mapGetters('fieldRecordSystem/navigation', [
      'entityLookup'
    ]),
    ...mapGetters('masterData', [
      'products'
    ]),
    ...mapGetters('fieldRecordSystem', [
      'orgUnitId'
    ]),
    ...mapState('masterData', {
      recommendedIds: state => state.portalSettings.recommended.products
    })
  },
  async created () {
    this.$store.dispatch('masterData/reloadCrops')

    const {orgUnitId} = this.$store.state.fieldRecordSystem.navigation.location

    const {data: previouslyUsedProducts} = await axios.get(`/api/v2/master-data/products/${orgUnitId}`)
    this.previouslyUsedProducts = previouslyUsedProducts
  }
}
</script>

<style lang="scss" module>

</style>
