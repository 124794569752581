<template lang="html">
  <LabelAndMessage
    :required="required"
    :feedback-classes="feedbackClasses"
    :description="combinedDescription" :message="message"
  >
    <div class="input-group">
      <input
        :id="id" type="text"
        class="form-control"
        :aria-describedby="`${id}-sr-status`"
        disabled
        :value="productDisplay" name="productDisplay"
      >

      <div class="input-group-btn">
        <button
          type="button" class="btn btn-primary"
          :disabled="readonly"
          @click.prevent="showModal = true"
        >
          <FontAwesomeIcon :icon="icons.edit" />
        </button>
      </div>
    </div>
    <Portal to="modals">
      <BsModal
        v-if="showModal"
        v-model="showModal"
        size="large"
        :blocking="false"
        title="Produkt auswählen"
      >
        <template v-if="finalType">
          <ProductSelectionSeed
            v-if="finalType === 'seed'"
            v-model="modelProxy"
            v-bind="{recommendedIds, previouslyUsedProducts, showArchived, filter: availableProductFilter, products, entityLookup, cropIds}"
            @input="showModal = false"
          />
          <ProductSelectionProtection
            v-if="finalType === 'protection'"
            v-model="modelProxy"
            v-bind="{recommendedIds, previouslyUsedProducts, showArchived, filter: availableProductFilter, products, entityLookup}"
            @input="showModal = false"
          />
          <ProductSelectionFertilizer
            v-if="finalType === 'fertilizer'"
            v-model="modelProxy"
            v-bind="{recommendedIds, previouslyUsedProducts, showArchived, filter: availableProductFilter, products, entityLookup}"
            @input="showModal = false"
          />
        </template>

        <ProductTypeSelection v-else @input="internalType = $event" />
      </BsModal>
    </Portal>
    <slot slot="label" name="label" />
  </LabelAndMessage>
</template>

<script>
import {modelProxy} from '@helpers/vuex'

import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import {faEdit} from '@fortawesome/free-solid-svg-icons'

import InputMixin from '@components/forms/input-base/InputMixin'
import FormPartMixin from 'src/vue/components/forms/FormPartMixin'
import ChangeDetectionMixin from 'src/vue/components/forms/ChangeDetectionMixin'
import RuleMixin from '@components/forms/RuleMixin'

import BsModal from '@components/bootstrap/BsModal'
import LabelAndMessage from '@components/forms/input-base/LabelAndMessage'

import ProductSelectionSeed from './ProductSelectionSeed'
import ProductSelectionProtection from './ProductSelectionProtection'
import ProductSelectionFertilizer from './ProductSelectionFertilizer'
import ProductTypeSelection from './ProductTypeSelection'

let counter = 0

export default {
  components: {
    FontAwesomeIcon,
    LabelAndMessage,
    ProductSelectionSeed,
    ProductSelectionProtection,
    ProductSelectionFertilizer,
    ProductTypeSelection,
    BsModal
  },
  mixins: [
    RuleMixin,
    InputMixin,
    FormPartMixin,
    ChangeDetectionMixin
  ],
  props: {
    value: String, // productId
    type: String, // seed, protection, fertilizer
    label: String,
    name: String,
    required: Boolean,
    availableProductFilter: Function,
    readonly: Boolean,
    entityLookup: Object,
    products: Object,
    cropIds: Array,
    recommendedIds: {
      type: Array,
      default: () => []
    },
    previouslyUsedProducts: {
      type: Array,
      default: () => []
    },
    showArchived: Boolean
  },
  data () {
    return {
      id: `product-picker-${counter++}`,
      dirty: false,
      showModal: false,
      internalType: null
    }
  },
  computed: {
    modelProxy,
    finalType () {
      return this.type || this.internalType
    },
    state () {
      // never validate without user entry
      if (!this.dirty) {
        return undefined
      }

      const states = {}

      if (this.required) {
        if (this.value && this.value !== '') {
          states.required = 'success'
        } else {
          states.required = 'error'
        }
      }
      states.rule = this.ruleState

      // the 'or success' part coerces the undefined state that you get when no rules are available into a success state
      return this.combineStates(states) || 'success'
    },
    combinedDescription () {
      return [this.ruleDescription, this.description].filter(x => x)
    },
    message () {
      const builtinMessages = {
        error: this.$i18n.translate('Messages.Info.SR_InfoMessages.RequiredField')
      }
      const combinedMessages = Object.assign(builtinMessages, this.ruleMessages)

      return combinedMessages[this.state]
    },
    productDisplay () {
      return this.value ? this.products[this.value].name : ''
    },
    icons () {
      return {
        edit: faEdit
      }
    }
  },
  watch: {
    showModal (value) {
      if (value) {
        this.internalType = null
        this.$emit('show')
      } else {
        this.$emit('hide')
      }
    }
  }
}
</script>

<style lang="scss" module>

</style>
