<template lang="html">
  <ProductFiltersBase v-model="baseModel" v-bind="{hasRecommendations}">
    <BsCheckboxList
      v-model="categories"
      :options="supportedCategories"
    />
  </ProductFiltersBase>
</template>

<script>
import supportedCategories from '@md/components/supported-protection-categories'

import BsCheckboxList from '@components/bootstrap/BsCheckboxList'

import baseModel from './product-filters-base-model'

import ProductFiltersBase from './ProductFiltersBase'

export default {
  components: {
    BsCheckboxList,
    ProductFiltersBase
  },
  props: {
    value: Object,
    hasRecommendations: Boolean
  },
  computed: {
    baseModel,
    categories: {
      get () {
        return this.value.categories
      },
      set (categories) {
        this.$emit('input', {...this.value, categories})
      }
    },
    supportedCategories () {
      return supportedCategories
    }
  }
}
</script>

<style lang="scss" module>
</style>
