<template lang="html">
  <ProductTileBase v-bind="{product, entityLookup, isRecommended, wasPreviouslyUsed}">
    <span :title="title">
      <IxRes>masterData.crops.crop.{{ product.cropId }}_name</IxRes>
    </span>
  </ProductTileBase>
</template>

<script>
import ProductTileBase from './ProductTileBase'

export default {
  components: {
    ProductTileBase
  },
  props: {
    /**
     * seed {...base, type: 'seed', cropId: string}
     */
    product: {
      type: Object,
      required: true
    },
    entityLookup: {
      type: Object,
      required: true
    },
    isRecommended: Boolean,
    wasPreviouslyUsed: Boolean
  },
  computed: {
    title () {
      return this.$i18n.translate(`masterData.crops.crop.${this.product.cropId}_name`)
    }
  }
}
</script>

<style lang="scss" module>
</style>
