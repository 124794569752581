<template lang="html">
  <ProductSelectionBase v-model="modelProxy" type="seed" v-bind="{entityLookup, filter, filters, showArchived, products, recommendedIds, previouslyUsedProducts}">
    <template #sidebar="{hasRecommendations}">
      <ProductFiltersSeed v-model="filters" v-bind="{cropIds, hasRecommendations}" />
    </template>

    <template #tile="scope">
      <ProductTileSeed v-bind="scope" />
    </template>
  </ProductSelectionBase>
</template>

<script>
import {modelProxy} from '@helpers/vuex'

import ProductSelectionBase from './ProductSelectionBase'
import ProductFiltersSeed from './ProductFiltersSeed'
import ProductTileSeed from './ProductTileSeed'

export default {
  components: {
    ProductSelectionBase,
    ProductFiltersSeed,
    ProductTileSeed
  },
  props: {
    value: String, // productId
    filter: Function,
    showArchived: Boolean,
    entityLookup: {
      type: Object,
      required: true
    },
    products: {
      type: Object,
      required: true
    }, // {[productId]: product, ...more}
    previouslyUsedProducts: {
      type: Array,
      default: () => []
    },
    recommendedIds: {
      type: Array,
      default: () => []
    } // [productId, ...more]
  },
  data () {
    return {
      filters: {
        showRecommendations: true,
        onlyPreviouslyUsed: false,
        search: '',
        cropId: null
      }
    }
  },
  computed: {
    modelProxy,
    cropIds () {
      return [...new Set(Object.values(this.products).map(x => x.cropId).filter(x => x))]
    }
  }
}
</script>

<style lang="scss" module>
</style>
