<template lang="html">
  <div class="map-palette-selector">
    <label>
      <IxRes>Common.SR_Common.MapPaletteSelector_label</IxRes>
    </label>
    <br>
    <template>
      <BsRadio
        v-for="(option, i) in options" :key="option"
        v-model="modelProxy"
        :value="option" inline
      >
        <div class="palette-option">
          <div class="palette-name">
            <IxRes>Common.SR_Common.MapPalette_{{ option }}</IxRes>
          </div>
          <div class="palette-preview">
            <div
              v-for="(color, j) in colors[i]" :key="j"
              class="palette-item"
              :style="{backgroundColor: color}"
            />
          </div>
        </div>
      </BsRadio>
    </template>
    <HelpBox class="palette-description">
      <IxRes>Common.SR_Common.MapPalette_{{ value }}_description</IxRes>
    </HelpBox>
  </div>
</template>

<script>
import HelpBox from '@components/help/HelpBox'
import BsRadio from '@components/bootstrap/BsRadio'

import {mapToColors} from '@helpers/map-color-schemes'
import {modelProxy} from '@helpers/vuex'

import PermissionMixin from '@mixins/PermissionMixin'

export default {
  components: {
    HelpBox,
    BsRadio
  },
  mixins: [
    PermissionMixin
  ],
  props: {
    options: Array,
    value: String,
    map: Object
  },
  computed: {
    modelProxy,
    colors () {
      if (!this.map) return []
      return this.options
        .filter(option => option !== 'autoZoneMapDebug' || this.$can('See.Frs.ExperimentalFeatures'))
        .map(option => {
          const sortByNumber = (a, b) => (a.number || Number(a.name)) - (b.number || Number(b.name))
          const sortByValue = (a, b) => (a.value === undefined ? a.number : a.value) - (b.value === undefined ? b.number : b.value)

          const zones = [...this.map.zones].sort(option === 'agrosat' ? sortByNumber : sortByValue)
          const sortedMap = {...this.map, zones}

          return mapToColors(sortedMap, option)
        })
    }
  },
  mounted () {
    this.modelProxy = 'agrosat'
  }
}
</script>

<style lang="scss" scoped>
.map-palette-selector {
  width: 100%;
}

.palette-option {
  display: flex;
  flex-direction: column;

  .palette-preview {
    align-self: stretch;
    display: flex;
    height: 2px;

    .palette-item {
      flex: 1
    }
  }
}

.palette-description {
  margin-top: 5px;
}
</style>
