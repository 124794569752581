<template lang="html">
  <ProductTileBase v-bind="{product, entityLookup, isRecommended, wasPreviouslyUsed}">
    <span :title="title">
      <span v-for="(category, i) in product.category" :key="category">
        <span v-if="i > 0">, </span>
        <IxRes>{{ supportedCategories.find(supported => supported.value === category).label }}</IxRes>
      </span>
    </span>
  </ProductTileBase>
</template>

<script>
import ProductTileBase from './ProductTileBase'
// TODO change products to use well-formed values instead of translations
import supportedCategories from '@md/components/supported-protection-categories'

export default {
  components: {
    ProductTileBase
  },
  props: {
    /**
     * protection {...base, type: 'protection', category: string[]}
     */
    product: {
      type: Object,
      required: true
    },
    entityLookup: {
      type: Object,
      required: true
    },
    isRecommended: Boolean,
    wasPreviouslyUsed: Boolean
  },
  computed: {
    supportedCategories () {
      return supportedCategories
    },
    title () {
      return this.product.category.map(category => this.$i18n.translate(supportedCategories.find(supported => supported.value === category).label)).join(', ')
    }
  }
}
</script>

<style lang="scss" module>
</style>
