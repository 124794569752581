<template lang="html">
  <div>
    <label>
      <IxRes>Areas.SimpleApplicationMaps.SR_SimpleApplicationMaps.Heterogenity</IxRes>
    </label>
    <div>
      <IxRes>Enums.SR_Enums.HeterogenityEnum_{{ zoneMap.heterogenity || 'None' }}</IxRes>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    zoneMap: Object
  }
}
</script>

<style lang="scss" scoped>
</style>
