export default [
  {value: 'Akarizid', label: 'masterData.product.categories.acaricidal'},
  {value: 'Bakterizid', label: 'masterData.product.categories.bactericidally'},
  {value: 'Desinfektionsmittel', label: 'masterData.product.categories.disinfectant'},
  {value: 'Fungizid', label: 'masterData.product.categories.fungicidal'},
  {value: 'Herbizid', label: 'masterData.product.categories.herbicide'},
  {value: 'Insektizid', label: 'masterData.product.categories.insecticidal'},
  {value: 'Leime, Wachse, Baumharze', label: 'masterData.product.categories.glues'},
  {value: 'Molluskizid', label: 'masterData.product.categories.molluscicide'},
  {value: 'Nematizid', label: 'masterData.product.categories.nematocide'},
  {value: 'Pheromon', label: 'masterData.product.categories.pheromone'},
  {value: 'Repellent, Wildschadenverhütungsmittel', label: 'masterData.product.categories.repellentContraceptive'},
  {value: 'Rodentizid', label: 'masterData.product.categories.rodenticide'},
  {value: 'Virizid', label: 'masterData.product.categories.viroid'},
  {value: 'Wachstumsregler', label: 'masterData.product.categories.grow'},
  {value: 'Pflanzenstärkungsmittel', label: 'masterData.product.categories.fortifier'},
  {value: 'Sonstiges', label: 'masterData.product.categories.other'}
]
