<template lang="html">
  <div class="zone-value-input">
    <HelpBox v-if="!zoneMap || !zoneMap.zones" type="danger">
      Invalid zonemap
    </HelpBox>
    <template v-else>
      <div v-for="(zone, index) in sortedZones" :key="zone.id" class="zone-row">
        <NumericInput
          v-if="!hideInput" required
          :value="value ? value[zone.id] : null"
          :addon="unit" :rule="rule"
          :percent="percent"
          :name="`ZoneValueInput_${index}`" @input="onInput(zone.id, $event)"
        >
          <template slot="label">
            <div class="color-icon" :style="{backgroundColor: colors[zoneMap.zones.indexOf(zone)]}" /> {{ `Zone ${zone.number}` }}
            <small class="text-muted">({{ $i18n.format(zone.area / 10000, 'f2') }} ha)</small>
          </template>
        </NumericInput>
        <label v-else>
          <div class="color-icon" :style="{backgroundColor: colors[zoneMap.zones.indexOf(zone)]}" /> {{ `Zone ${zone.number}` }}
          <small class="text-muted">({{ $i18n.format(zone.area / 10000, 'f2') }} ha)</small>
        </label>
      </div>
    </template>
  </div>
</template>

<script>
import {mapState} from 'vuex'

import sortBy from 'lodash/sortBy'
import NumericInput from '@components/forms/NumericInput'
import HelpBox from '@components/help/HelpBox'
import {mapToColors} from '@helpers/map-color-schemes'

export default {
  components: {
    NumericInput,
    HelpBox
  },
  props: {
    value: Object,
    zoneMap: Object,
    unit: String,
    rule: Function,
    hideInput: Boolean,
    percent: Boolean
  },
  computed: {
    ...mapState('fieldRecordSystem/mapManagement', {
      palette: state => state.ui.zoneMapPalette
    }),
    sortedZones () {
      return sortBy(this.zoneMap.zones, 'number')
    },
    colors () {
      return mapToColors(this.zoneMap, this.palette)
    }
  },
  methods: {
    onInput (zoneId, value) {
      const newValue = {}
      for (const zone of this.zoneMap.zones) {
        if (zone.id === zoneId) {
          newValue[zone.id] = value
        } else if (this.value) {
          newValue[zone.id] = this.value[zone.id]
        } else {
          newValue[zone.id] = null
        }
      }
      this.$emit('input', newValue)
    }
  }
}
</script>

<style lang="scss" scoped>
.zone-value-input {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}
.color-icon {
  display: inline-block;
  width: 10px;
  height: 10px;
  // border-radius: 5px;
}
</style>
