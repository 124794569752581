<template lang="html">
  <ProductTileBase v-bind="{product, entityLookup, isRecommended, wasPreviouslyUsed}">
    <span :title="title">
      <IxRes>masterData.{{ product.isOrganic ? 'organic' : 'mineral' }}</IxRes>
      <span>, </span>
      <IxRes>masterData.{{ product.isLiquid ? 'liquid' : 'solid' }}</IxRes>
    </span>
  </ProductTileBase>
</template>

<script>
import ProductTileBase from './ProductTileBase'

export default {
  components: {
    ProductTileBase
  },
  props: {
    /**
     * fertilizer {...base, type: 'fertilizer', isOrganic: boolean, isLiquid: boolean}
     */
    product: {
      type: Object,
      required: true
    },
    entityLookup: {
      type: Object,
      required: true
    },
    isRecommended: Boolean,
    wasPreviouslyUsed: Boolean
  },
  computed: {
    title () {
      return `${this.$i18n.translate(`masterData.${this.product.isOrganic ? 'organic' : 'mineral'}`)}, ${this.$i18n.translate(`masterData.${this.product.isLiquid ? 'liquid' : 'solid'}`)}`
    }
  }
}
</script>

<style lang="scss" module>
</style>
